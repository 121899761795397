.carousel-jisho {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: auto;
  align-self: center;
  margin-top: 30px;
}

.headline {
  font-size: 1.5rem;
}
