.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 40%;
  align-self: center;
  margin-top: 50px;
}

.slide {
  border-radius: 0.5rem;
  box-shadow: 0px 0px 7px #666;
  width: 100%;
  height: 100%;
}

.slide-hidden {
  display: none;
}

.arrow {
  position: absolute;
  width: 2rem;
  height: 2rem;
  color: white;
  filter: drop-shadow(0px 0px 5px #555);
}

.arrow-left {
  left: 0.5rem;
}

.arrow-right {
  right: 0.5rem;
}

.arrow:hover {
  cursor: pointer;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: white;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive {
  background-color: grey;
}

.end-text {
  margin-bottom: 1rem;
}

@media screen and (max-width: 650px) {
  .carousel {
    width: 60%;
    height: 20%;
  }
}
