.header {
  text-align: center;
}

.clock {
  height: 8%;
  margin-bottom: 0.5rem;
}

.time-container {
  width: 95%;
  height: 80%;
  border-radius: 0.5rem;
  background-color: rgb(209, 252, 213);
  box-shadow: 0rem 0.2rem 0.8rem rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
}
.request-time-container {
  height: 35%;
}
.request-time-text {
  margin-left: 1rem;
  box-shadow: -2px -2px grey;
}

.enter-bar {
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-input {
  width: 45%;
  left: 5%;
  border-radius: 0.3rem;
  height: 100%;
  font-size: 130%;
  text-align: center;
  font-weight: 900;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #cc95c0 0%,
    #dbd4b4 51%,
    #cc95c0 100%
  );
  margin: 10px;
  padding: 3px 5px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.request-result-container {
  text-align: center;
  height: 25%;
  border-radius: 0.5rem;
  background-color: snow;
  border: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: 2vw;
  font-size: 130%;
  font-weight: 900;
  border-color: #cc95c0;
  box-shadow: 1px 0.2px 0.1px grey;
}

.arrow-container {
  margin-bottom: 0px;
  height: 15%;
}

.arrow {
  height: 100%;
}

.convert-time-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 5%;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.from-location-text {
  margin-left: 1rem;
}

.from-location-input {
  position: absolute;
  right: 5%;
  width: 45%;
  border-radius: 0.3rem;
  height: 3%;
  font-size: 130%;
  text-align: center;
  font-weight: 900;
}

.globe-button {
  width: 10%;
  align-self: center;
  background: transparent;
  border: none;
}

.convert-result-container {
  height: 25%;
}
.convert-container {
  text-align: center;
  height: 40%;
  border-radius: 0.5rem;
  background-color: snow;
  border: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: 2vw;
  font-size: 130%;
  font-weight: 900;
  border-color: #cc95c0;
  box-shadow: 1px 0.2px 0.1px grey;
}
