.detail-container {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1500;
  background-color: #f1f2eb;
  border-radius: 8px;
  overflow: scroll;
}

.close {
  font-size: 2.5rem;
  right: 1.2rem;
  top: 0;
  position: absolute;
}

.youtube-video {
  align-self: center;
}

.subtitle {
  margin-top: 0.5rem;
  align-self: center;
}

.time-info {
  font-style: italic;
}

.text-part {
  margin-left: 12.5%;
  margin-top: 1rem;
  width: 75%;
}

.description {
  margin-top: 0.5rem;
  color: var(--title-color-dark);
}

.download-link {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .detail-container {
    width: 100%;
  }

  .subtitle {
    font-size: 1rem;
  }

  .headline {
    font-size: 1rem;
  }

  .text-part {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .youtube-video {
    height: 12%;
    width: 65%;
  }
  /* .text-part{
    font-size: 0.6rem;
    
  }
  .subtitle{
    font-size: .8rem
  }
  .headline{
    font-size: 0.8rem;
  } */
}

@media screen and (max-width: 435px) {
  .text-part {
    font-size: 0.75rem;
  }
  .subtitle {
    font-size: 0.85rem;
  }
  .headline {
    font-size: 0.85rem;
  }
}
