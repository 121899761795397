.blog_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--container-color);
  width: 45%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 1.2rem;
  font-size: 1.25rem;
  color: var(--title-color);
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.statement-cover {
  width: 320px;
  height: 220px;
}

@media screen and (max-width: 576px) {
  .blog_container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.875rem;
  }
}
.blog_container:hover {
  .statement-cover {
    opacity: 0.1;
  }
  .check-out {
    display: block;
    font-weight: 900;
  }
}
